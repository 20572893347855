import React, { useEffect, useRef, useState } from "react";
import '../../assets/css/styles.css';
import './css/index.css';
import { firstLetter, scrollToTop, getSearchParams } from "../../utilities/util";
import axios from "axios";
import Config from "../../config/config";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import Header from "../components/header";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import store from "../../app/store";
import {
    clearSearchParams,
    setKategorijaTipSearchParam,
    setPolSearchParams,
    setStartSearchParams
} from "../../app/store/searchParams/actions";
import FilterBoje from "../components/filterBoje";
import FilterBrendovi from "../components/filterBrendovi";
import history from "../../utilities/history";
import Footer from "../components/footer";
import Filter from "./filter";
import ProductCard from "../pages/product-info";
import { useMediaQuery } from 'react-responsive';

export const ProductListPage = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    const state = useSelector(state => state);
    const [total, setTotal] = useState(0);
    const [products, setProducts] = useState(null);
    const [categoriesData, setCategoriesData] = useState(null);
    const [renderPage, setRenderPage] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [searchParams, setSearchParams] = useState({});
    const [start, setStart] = useState(1);
    const [colors, setColors] = useState([]);
    const [brands, setBrands] = useState([]);

    const bottomPageRef = useRef();

    useEffect(() => {
        scrollToTop();
        const params = getSearchParams(state.searchParams);
        let api = `${Config.api.baseUrl}v1/proizvod`;
        if (!params.start) {
            api += '?start=1&pol=zenski'
        }
        axios.get(api, { params })
            .then(res => {
                setProducts(res.data.data.proizvodi);
                setTotal(res.data.data.total);
            })
        axios.get(`${Config.api.baseUrl}v1/kategorijatip?pol=${state.searchParams.pol}`)
            .then(res => {
                setCategoriesData(res.data.data);
            })
        setRenderPage(true);
    }, [])

    useEffect(() => {
        scrollToTop();
        const params = getSearchParams(state.searchParams);
        let api = `${Config.api.baseUrl}v1/proizvod`;
        if (!params.start) {
            api += '?start=1&pol=zenski'
        }
        axios.get(api, { params })
            .then(res => {
                setProducts(res.data.data.proizvodi);
                setTotal(res.data.data.total);
            })
        axios.get(`${Config.api.baseUrl}v1/kategorijatip?pol=${state.searchParams.pol}`)
            .then(res => {
                setCategoriesData(res.data.data);
            })
        setRenderPage(true);
        setActivePage(Math.ceil(state.searchParams.start / 20));
    }, [state, colors, brands]);

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setStart(Number(pageNumber * 20 - 19));
        store.dispatch(setStartSearchParams(pageNumber * 20 - 19));
    }

    const iteratePages = () => {
        let pages = [];
        for (let i = 1; i <= total / 19; i++) {
            pages.push(<div className="cursor-pointer" onClick={() => handlePageChange(i)}><a className={activePage === i ? `page-link active-page` : `page-link color-tamara`}>{i}</a></div>);
        }
        return pages;
    }

    return (<>
        {
            products === null && categoriesData === null ? <Loader /> :
                <>
                    <Header state={this} showMenu={false} isProductList={true} />
                    <Container fluid className="justify-content-center pt-2 mt-md-0 pt-md-0 px-0">
                        <div className="submenu d-flex justify-content-center">
                            <div className="submenu-item d-flex justify-content-center">
                                <FilterBoje setColors={setColors} colors={colors} />
                                <FilterBrendovi setBrands={setBrands} brands={brands} />
                            </div>
                        </div>
                        {/* <div className='mt-5 mb-0 breadcrumb'>
                            <a className='breadcrumb-item' onClick={()=> {
                                // @ts-ignore
                                store.dispatch(clearSearchParams());
                                store.dispatch(setPolSearchParams(state.searchParams.pol ? state.searchParams.pol : "zenski"));
                            }}>{state.searchParams.pol === 'zenski' ? 'Žene' : 'Muškarci'} </a>
                            {
                                state.searchParams.kategorija !== 0 &&
                                <a
                                    onClick={() => store.dispatch(setKategorijaTipSearchParam(Number(state.searchParams.kategorija), 0))}
                                    className='breadcrumb-item'>{firstLetter(`${categoriesData?.find((item: any) => item.value === state.searchParams.kategorija)?.label}`)}</a>
                            }
                            {
                                state.searchParams.tip !== 0 &&
                                <a href="/product-list"
                                   className='breadcrumb-item'>{firstLetter(`${categoriesData?.find((item: any) => item.value === state.searchParams.kategorija)?.tip.find((item: any) => item.value === state.searchParams.tip)?.label}`)}</a>
                            }
                        </div> */}
                        <div className="d-flex">
                            <div className="filters pt-4 d-none d-md-block">
                                <div className="filter-name rounded-3 pt-3 ps-3 mb-2">
                                    <a className='ff-syncopate-bold' onClick={() => window.location.reload()}>{state.searchParams.pol === 'zenski' ? 'ZA NJU' : 'ZA NJEGA'}</a>
                                </div>
                                <div className="filter-section ps-3">
                                    {
                                        categoriesData?.map((catData) => {
                                            return (
                                                <Filter filter={catData} pol={state.searchParams.pol} />
                                            );
                                        })
                                    }
                                </div>
                            </div>
                            <div style={{ backgroundColor: "#F4F4F4", marginTop: '15px' }} className="d-flex justify-content-evenly flex-wrap pt-3">
                                {
                                    products?.map((product) => {
                                        return (
                                            <ProductCard
                                                product={product}
                                                onClick={() => history.push(`/product/${product.id}`)}
                                            />
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div ref={bottomPageRef} />
                            <div className={`mt-5 d-flex ${isMobile && 'overflow-x'} pagination cursor-pointer color-tamara`}>
                                <div onClick={() => handlePageChange(activePage - 1)}>
                                    <p className="page-link color-tamara" aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </p>
                                </div>
                                {
                                    iteratePages()
                                }
                                <div onClick={() => handlePageChange(activePage + 1)}>
                                    <a className="page-link color-tamara" href="#" aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </div>
                            </div>
                    </Container>
                    <Footer />
                </>
        }
    </>);
}