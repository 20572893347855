import React, { useEffect, useState } from "react";
import '../../../assets/css/styles.css';
import './css/index.css';
import Container from 'react-bootstrap/Container';
import Header from "../../components/header";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import Config from "../../../config/config";
import history from "../../../utilities/history";
import { scrollToTop } from "../../../utilities/util";
import LurreButton from "../../components/lurreButton";
import { FaEdit } from "react-icons/fa";
import { FaTrashAlt } from "react-icons/fa";
import { MdDiscount } from "react-icons/md";

function ProductListPage(props) {
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        scrollToTop();
        const handleKeyPress = (event) => {
            if (event.key === 'Enter') {
                props.setSearch(`&searchTerm=${document.getElementById('pretraga').value}`)
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const deleteComponent = () => {
        if (props.item !== null) {
            axios.delete(`${Config.api.baseUrl}v1/proizvod/${props.item.id}`, {
                data: { naziv: props.item.naziv },
                headers: { "Authorization": localStorage.getItem("BearerToken") }
            }).then((response) => {
                if (response.status === 200) {
                    alert(`Uspešno obrisana komponenta: ${props.item.naziv}`);
                }
                if (response.status === 401) {
                    alert(`Nemate prava za brisanje proizvoda, Molimo vas da se ulogujete.`);
                }
            });
            setShowPopup(false);
        }
    }

    return (
        <>
            {
                showPopup &&
                <>
                    <div className='background-popup' />
                    <div className='popup-delete text-center p-4'>
                        <p>{`Da li ste sigurni da želite da obrišete komponentu ${props.item.naziv}?`}</p>
                        <Button variant="danger" className='me-3'
                            onClick={() => deleteComponent()}>Da</Button>
                        <Button onClick={() => setShowPopup(false)}>Ne</Button>
                    </div>
                </>
            }
            {
                props.renderPage ?
                    <>
                        <Container>
                            <Row className='mb-4 mt-3'>
                                <Col md='4 mt-3'></Col>
                                <Col md='4 mt-3'>
                                    <input className='w-100 mt-1' type='text' id='pretraga' style={{ height: '35px', borderRadius: '5px' }} />
                                </Col>
                                <Col md='4 mt-3 text-center'>
                                    <LurreButton className='mx-2' onClick={() => props.setSearch(`&searchTerm=${document.getElementById('pretraga').value}`)} text='Pretraži' />
                                    <LurreButton className='mx-2' onClick={() => props.setSearch('')} text='Otkaži pretragu' />
                                </Col>
                            </Row>
                            <Row>
                                <div className='product-list-nav mx-auto'>
                                    <Row className='bg-masa text-center text-white'>
                                        <Col md='1' className='pt-3 p-0 col-2'>
                                            <p>{`ID`}</p>
                                        </Col>
                                        <Col md='3' className='pt-3 p-0 col-2'>
                                            <p>{`NAZIV`}</p>
                                        </Col>
                                        <Col md='3' className='pt-3 p-0 col-2'>
                                            <p>{`BREND`}</p>
                                        </Col>
                                        <Col md='3' className='pt-3 p-0 col-3'>
                                            <p>{`KATEGORIJA`}</p>
                                        </Col>
                                        <Col md='2' className='pt-3 p-0 col-3'>
                                            <p>{`AKCIJE`}</p>
                                        </Col>
                                    </Row>
                                </div>
                                <div className='product-list mx-auto'>
                                    {
                                        props.proizvodi && props.proizvodi.proizvodi.map((proizvod) => {
                                            return (
                                                <>
                                                    <Row className='text-center border-bottom-black'>
                                                        <Col md='1' className='pt-3 p-0 col-2'>
                                                            <p>{`${proizvod.id}`}</p>
                                                        </Col>
                                                        <Col md='3' className='pt-3 p-0 col-2'>
                                                            <p>{`${proizvod.naziv}`}</p>
                                                        </Col>
                                                        <Col md='3' className='pt-3 p-0 col-2'>
                                                            <p>{`${proizvod.brend}`}</p>
                                                        </Col>
                                                        <Col md='3' className='pt-3 p-0 col-3'>
                                                            <p>{`${proizvod.kategorija}`}</p>
                                                        </Col>
                                                        <Col md='2' className='pt-3 p-0 col-3'>
                                                            <MdDiscount className="cursor-pointer me-2" style={{ height: '25px', width: '25px' }} onClick={() => {
                                                                    history.push('/add-discount-one', { state: proizvod.id })
                                                                }}/>
                                                            <FaEdit className="cursor-pointer me-2" style={{ height: '25px', width: '25px' }} onClick={() => {
                                                                history.push('/edit-product', { state: proizvod.id })
                                                            }} />
                                                            <FaTrashAlt className="cursor-pointer" style={{ height: '25px', width: '25px' }}
                                                                onClick={() => {
                                                                    props.setItem(proizvod);
                                                                    setShowPopup(true);
                                                                }} />
                                                        </Col>
                                                    </Row>
                                                </>
                                            );
                                        })
                                    }
                                </div>
                            </Row>
                        </Container>
                    </> :
                    <p className='text-center h4 mt-20'>Molimo ulogujte se <a href="" className="link"
                        onClick={() => history.push('/admin')}>ovde</a>
                    </p>
            }
        </>
    );
}

export default ProductListPage;
