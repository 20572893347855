import Header from "../../components/header";
import { useState, useEffect } from "react";
import Footer from "../../components/footer";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import Config from "../../../config/config";
import { MdOutlineDoneOutline } from 'react-icons/md';
import { scrollToTop } from "../../../utilities/util";
import { MdErrorOutline } from "react-icons/md";

export default function CheckoutPage() {
    const [successfulCheckout, setSuccessfulCheckout] = useState(false);
    const [checkoutError, setCheckoutError] = useState(false);
    const [brojKupovine, setBrojKupovine] = useState('000000');

    useEffect(() => {
        scrollToTop();
    }, [])

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        const korisnik = {};
        korisnik.ime = form.ime.value;
        korisnik.prezime = form.prezime.value;
        korisnik.adresa = form.adresa.value;
        korisnik.grad = form.grad.value;
        korisnik.stan = form.stan.value;
        korisnik.telefon = form.telefon.value;
        korisnik.email = form.email.value;

        const cartItems = JSON.parse(localStorage.getItem("cartItems"));

        axios.post(`${Config.api.baseUrl}v1/checkout`, {
            korisnik: korisnik,
            napomena: form.napomena.value,
            cartItems: cartItems
        }
        ).then((response) => {
            if (response.status === 200) {
                localStorage.setItem("cartItems", null);
                setSuccessfulCheckout(true);
                setBrojKupovine(response.data.data.id);
                setCheckoutError(false);
            } else {
                setCheckoutError(true);
            }
            scrollToTop();
        });

    }
    return (
        <>
            <Header />
            {
                checkoutError ?
                    <>
                        <div className='container-fluid mt-5 pt-5 text-center'>
                                    <MdErrorOutline className='mt-3 ms-3 menu-icon' />
                                    <p>TRENUTNO NE MOŽEMO DA PRIMIMO VАŠU PORUDŽBINU.</p>
                                    <p>MOLIMO VAS DA NAM SE JAVITE NA BROJ TELEFONA: 063 / 252 558 ILI KASNIJE PROBATE PONOVO.</p>
                                </div>
                    </> :
                    <>
                        {
                            successfulCheckout ?
                                <div className='container-fluid mt-5 pt-5 text-center'>
                                    <MdOutlineDoneOutline className='mt-3 ms-3 menu-icon' />
                                    <p>USPEŠNO STE PORUČILI PROIZVODE IZ KORPE! JAVIĆEMO VAM SE U NAJKRAĆEM MOGUĆEM ROKU!</p>
                                    <p>VAŠ BROJ KUPOVINE JE: {brojKupovine}</p>
                                </div> :
                                <div className='container-fluid checkout-block mt-5 pt-5 d-flex'>
                                    <form onSubmit={handleSubmit} className="mb-5 flex-column justify-content-center">
                                        <Form.Group controlId="ime">
                                            <Form.Label>Ime:</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Ime"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="prezime">
                                            <Form.Label>Prezime:</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Prezime"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="adresa">
                                            <Form.Label>Adresa:</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Adresa"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="grad">
                                            <Form.Label>Grad:</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Grad"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="stan">
                                            <Form.Label>Broj stana/sprat:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Broj stana/sprat"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="telefon">
                                            <Form.Label>Kontakt telefon:</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Kontakt telefon"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="email">
                                            <Form.Label>E-mail:</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="E-mail"
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="napomena">
                                            <Form.Label>Napomena:</Form.Label>
                                            <Form.Control as="textarea" defaultValue=" " required />
                                        </Form.Group>
                                        <div className='mt-2 text-center'>
                                            <Button type="submit" style={{ borderRadius: '0', backgroundColor: 'black', color: 'white', border: 'none' }}>PORUČI</Button>
                                        </div>
                                    </form>
                                </div>
                        }
                    </>
            }

            <Footer />
        </>
    );
}