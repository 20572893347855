import React, { useEffect, useState } from 'react';
import Header from "../../components/header";
import "../../../assets/css/styles.css";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { currencyFormat, maxCharacters, removeFromCart, scrollToTop } from "../../../utilities/util";
import history from "../../../utilities/history";
import Button from 'react-bootstrap/Button';
import { BsTrashFill } from 'react-icons/bs';
import { useMediaQuery } from 'react-responsive';

export default function CartPage() {
    const cartItems = JSON.parse(localStorage.getItem("cartItems"));
    const [kolicina, setKolicina] = useState(1);
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    function fullPrice(cartItems) {
        let price = 0;
        cartItems?.forEach((cartItem) => {
            if (cartItem.proizvod.cenaSaPopustom) {
                price += Math.round(cartItem.kolicina * cartItem.proizvod.cenaSaPopustom);
            } else {
                price += Math.round(cartItem.kolicina * cartItem.proizvod.cena);
            }
        });
        return price;
    }

    function handleChangeKolicina(event) {
        setKolicina(event.target.value);
    }

    function handleEdit(id, cartId, cartItems) {
        const item = cartItems?.find((itemCart) => itemCart.proizvod.id === id);
        removeFromCart(cartId);
        localStorage.setItem('editItem', JSON.stringify(item));
        history.push(`/product/${id}`);
    }

    const ukupnaCena = fullPrice(cartItems) < 1500 ? fullPrice(cartItems) + 300 : fullPrice(cartItems);

    return (
        <>
            {
                showDeletePopup &&
                <>
                    <div className='background-popup' />
                    <div className='popup-delete text-center p-4 d-flex align-items-center justify-content-center'>
                        <div>
                            <p className='h5'>Proizvod je uspešno izbrisan iz korpe!</p>
                            <Button className='me-3 mt-3' style={{ backgroundColor: 'black', color: 'white', borderRadius: '0', border: 'none' }} onClick={() => {
                                setShowDeletePopup(false);
                            }}>Ok</Button>
                        </div>
                    </div>
                </>
            }
            <Header />
            {
                <div className='row mt-5 pt-3 mt-xl-0 pt-xl-0' style={{ minHeight: "1000px" }}>
                    <div className={`col-xl-8 ${(!cartItems || cartItems?.length === 0) ? 'col-4' : 'col-8'} cart-items`}>
                        <div className="d-flex justify-content-center flex-column-reverse">
                            {
                                cartItems?.map((item) => {
                                    return (
                                        <div className='cartItem d-flex flex-wrap align-items-center justify-content-between my-3 mx-5 p-1 mx-auto' style={{ position: "relative", width: '80%' }}>
                                            <div className='d-flex'>
                                                {
                                                    !isMobile &&
                                                    <div>
                                                        <img
                                                            className="cart-item-img my-2 ms-2 me-5"
                                                            src={process.env.PUBLIC_URL + `/Imgs/${item.proizvod.defaultSlika}`}
                                                            alt="slider-photo"
                                                        />
                                                    </div>
                                                }

                                                <div className='ms-3 mt-2'>
                                                    <p className='mb-1'>{item.proizvod.brend} | {item.proizvod.moda ? 'Moda' : 'Klasika'}</p>
                                                    <p className=' mb-2' style={{ fontSize: '20px', fontWeight: '600' }}>{item.proizvod.naziv}</p>
                                                    <div className='d-flex'>
                                                        <p className=''>Veličina: <b>{item.velicina}</b></p>
                                                        {item.dubinaKorpe &&
                                                            <p className='ms-3'>Dubina korpe: <b>{item.dubinaKorpe}</b></p>}
                                                    </div>
                                                    <p >Količina: <b>{item.kolicina}</b></p>
                                                    <div className='d-flex'>
                                                        <p>Boja: </p>
                                                        <div className='me-4 ms-1'>
                                                            <img
                                                                style={{ width: '30px', height: '30px' }}
                                                                src={process.env.PUBLIC_URL + `/Imgs/boje/${item.boja}.jpg`}
                                                                alt={""}
                                                            />
                                                            <p className="colorName ms-1">{maxCharacters(item.boja)}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {
                                                isMobile ?

                                                    <p className="ms-3" style={{ fontSize: '20px', fontWeight: '600'}}>{item.proizvod.cenaSaPopustom ?
                                                        <><del>{currencyFormat(item.proizvod.cena)}</del> {currencyFormat(Math.round(item.proizvod.cenaSaPopustom))}</> : currencyFormat(item.proizvod.cena)} <span className='text-15'>RSD</span></p> :

                                                    <p style={{ fontSize: '25px', fontWeight: '600', position: 'absolute', top: '20px', right: '20px' }}>{item.proizvod.cenaSaPopustom ?
                                                        <><del>{currencyFormat(item.proizvod.cena)}</del> {currencyFormat(Math.round(item.proizvod.cenaSaPopustom))}</> : currencyFormat(item.proizvod.cena)} <span className='text-15'>RSD</span></p>
                                            }
                                            <div>
                                                <p className='m-3 cursor-pointer' style={{ textDecoration: 'underline' }} onClick={() => handleEdit(item.proizvod.id, item.cartId, cartItems)}>Izmeni</p>
                                                <p className='m-3 cursor-pointer' style={{ textDecoration: 'underline' }} onClick={() => {
                                                    removeFromCart(item.cartId);
                                                    setShowDeletePopup(true);
                                                    scrollToTop();
                                                }}>Obriši</p>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                    <div className={`pt-1 col-xl-4 ${(!cartItems || cartItems?.length === 0) ? 'col-8' : 'col-4'}`}  style={{ backgroundColor: 'black', color: 'white' }}>
                        <p className='mb-5'>Trenutna porudžbina:</p>
                        {
                            cartItems && cartItems.length > 0 ? <>
                                <p className="mb-0"> {currencyFormat(fullPrice(cartItems))} RSD</p>
                                {
                                    fullPrice(cartItems) < 1500 &&
                                    <>
                                        <p className="mb-0">+ 300.00 RSD</p>
                                        <p style={{ fontSize: '14px', color: 'grey' }}>Cena pošiljke za kupovine jeftinije od 1,500.00 RSD</p>
                                    </>
                                }
                                <p style={{ color: 'grey' }} className='mb-1 mt-3'>Ukupna cena: </p>
                                <p className="mb-0"> {currencyFormat(ukupnaCena)} RSD</p>
                                <div className="buy-btn text-center py-2 mt-2 justify-content-center mx-auto mb-5" style={{ backgroundColor: "white", color: "black" }}
                                    onClick={() => {
                                        history.push(`/checkout`);
                                    }}><b>KUPI</b>
                                </div>
                            </> :
                                <p className='text-center text-22 mt-5'>Trenutno nema proizvoda u korpi!</p>
                        }
                    </div>
                </div>
            }
        </>
    );
}