import React, { useEffect, useState } from "react";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Config from "../../../config/config";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import history from "../../../utilities/history";
import LurreButton from "../../components/lurreButton";
import { scrollToTop } from "../../../utilities/util";
import { FaTrashAlt } from "react-icons/fa";

export default function OrdersPage() {
    const [status, setStatus] = useState('active');
    const [orders, setOrders] = useState([]);
    const [brojKupovine, setBrojKupovine] = useState('');

    useEffect(() => {
        scrollToTop();
        axios.get(`${Config.api.baseUrl}v1/checkout`, {
            body: {
                status: status
            },
            headers: { "Authorization": localStorage.getItem("BearerToken") }
        }).then(res => {
            setOrders(res.data.data);
        })
    }, []);

    useEffect(() => {
        if (status) {
            axios.get(`${Config.api.baseUrl}v1/checkout?status=${status}`, {
                headers: { "Authorization": localStorage.getItem("BearerToken") }
            }).then(res => {
                setOrders(res.data.data);
            }).catch(err => {
                setOrders([]);
            });
        }
    }, [status]);

    useEffect(() => {
        if (brojKupovine) {
            axios.get(`${Config.api.baseUrl}v1/checkout?brojKupovine=${brojKupovine}`, {
                headers: { "Authorization": localStorage.getItem("BearerToken") }
            }).then(res => {
                setOrders(res.data.data);
            }).catch(err => {
                setOrders([]);
            });
        }
    }, [brojKupovine]);

    const deleteCanceled = () => {
        axios.delete(`${Config.api.baseUrl}v1/checkout/canceled`, {
            headers: { "Authorization": localStorage.getItem("BearerToken") }
        }).then(res => {
            window.location.reload(false);
        }).catch(err => {
            console.error(err);
        });
    }

    const deleteConfirmed = () => {
        axios.delete(`${Config.api.baseUrl}v1/checkout/confirmed`, {
            headers: { "Authorization": localStorage.getItem("BearerToken") }
        }).then(res => {
            window.location.reload(false);
        }).catch(err => {
            console.error(err);
        });
    }

    const deleteOrder = (brojKupovine) => {
        axios.delete(`${Config.api.baseUrl}v1/checkout/${brojKupovine}`, {
            headers: { "Authorization": localStorage.getItem("BearerToken") }
        }).then(res => {
            window.location.reload(false);
        }).catch(err => {
            console.error(err);
        });
    }

    return (
        <>
            <div className='d-flex mt-5 container justify-content-between width-350'>
                <LurreButton text='Aktivne' onClick={() => {
                    setBrojKupovine('');
                    setStatus('active');
                }} />
                <div>
                    <LurreButton text='Otkazane' onClick={() => {
                        setBrojKupovine('');
                        setStatus('canceled');
                    }} />

                    {
                        status === 'canceled' &&
                        <FaTrashAlt className="cursor-pointer ms-3" style={{ height: '25px', width: '25px' }}
                            onClick={() => {
                                deleteCanceled();
                            }} />
                    }
                </div>
                <div>
                    <LurreButton text='Uspešne' onClick={() => {
                        setBrojKupovine('');
                        setStatus('success');
                    }} />
                    {
                        status === 'success' &&
                        <FaTrashAlt className="cursor-pointer ms-3" style={{ height: '25px', width: '25px' }}
                            onClick={() => {
                                deleteConfirmed();
                            }} />
                    }
                </div>
            </div>
            <div className='d-flex mt-5 container justify-content-center width-350'>
                <Col md='4 mt-3'>
                    <input className='w-100 mt-1' type='text' id='pretragaOrdera' />
                </Col>
                <Col md='4 mt-3 text-center'>
                    <LurreButton text='Pretraži' onClick={() => {
                        setStatus('');
                        setBrojKupovine(document.getElementById('pretragaOrdera').value);
                    }} />
                </Col>
            </div>
            <Row className='text-center bg-masa mt-2'>
                <Col md='2' className='py-2 p-0'>BROJ KUPOVINE</Col>
                <Col md='2' className='py-2 p-0'>STATUS</Col>
                <Col md='2' className='py-2 p-0'>IME</Col>
                <Col md='2' className='py-2 p-0'>PREZIME</Col>
                <Col md='2' className='py-2 p-0'>TELEFON</Col>
                <Col md='2' className='py-2 p-0'>AKCIJE</Col>
            </Row>

            <div className='show-table'>
                {
                    orders && orders.map((order) => {
                        return (
                            <Row className='text-center border mt-2 cursor-pointer' >
                                <Col md='2' className='py-2 p-0' onClick={() => history.push(`/order/${order.brojKupovine}`)}>{order.brojKupovine}</Col>
                                <Col md='2' className='py-2 p-0' onClick={() => history.push(`/order/${order.brojKupovine}`)}>{order.status}</Col>
                                <Col md='2' className='py-2 p-0' onClick={() => history.push(`/order/${order.brojKupovine}`)}>{order.korisnikId.ime}</Col>
                                <Col md='2' className='py-2 p-0' onClick={() => history.push(`/order/${order.brojKupovine}`)}>{order.korisnikId.prezime}</Col>
                                <Col md='2' className='py-2 p-0' onClick={() => history.push(`/order/${order.brojKupovine}`)}>{order.korisnikId.telefon}</Col>
                                <Col md='2' className='py-2 p-0'>
                                    <FaTrashAlt className="cursor-pointer ms-3" style={{ height: '25px', width: '25px' }}
                                        onClick={() => {
                                            deleteOrder(order.brojKupovine);
                                        }} />
                                </Col>
                            </Row>
                        );

                    })
                }
            </div>
        </>
    );
}