import Header from "../../components/header";
import { useEffect, useState } from "react";
import Config from "../../../config/config";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import LurreButton from "../../components/lurreButton";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

export default function PopularProducts() {
    const [popularProducts, setPopularProducts] = useState(null);
    const [p1, setP1] = useState(null);
    const [p2, setP2] = useState(null);
    const [p3, setP3] = useState(null);
    const [p4, setP4] = useState(null);
    const [p5, setP5] = useState(null);
    const [id, setId] = useState(null);

    useEffect(() => {
        axios.get(`${Config.api.baseUrl}v1/popular-products`)
            .then(res => {
                if (res.status === 200) {
                    setPopularProducts(res.data.data);
                }
            })

    }, [])

    useEffect(() => {
        if (popularProducts) {
            axios.get(`${Config.api.baseUrl}v1/proizvod/${popularProducts.p1}`)
                .then(res => {
                    setP1(res.data.data);
                })
            axios.get(`${Config.api.baseUrl}v1/proizvod/${popularProducts.p2}`)
                .then(res => {
                    setP2(res.data.data)
                })
            axios.get(`${Config.api.baseUrl}v1/proizvod/${popularProducts.p3}`)
                .then(res => {
                    setP3(res.data.data)
                })
            axios.get(`${Config.api.baseUrl}v1/proizvod/${popularProducts.p4}`)
                .then(res => {
                    setP4(res.data.data)
                })
            axios.get(`${Config.api.baseUrl}v1/proizvod/${popularProducts.p5}`)
                .then(res => {
                    setP5(res.data.data)
                })
        }
    }, [popularProducts])

    function handleUpdate(index) {
        axios.put(`${Config.api.baseUrl}v1/popular-products/${index}`, { index: id }, {
            headers: { "Authorization": localStorage.getItem("BearerToken") }
        }).then((response) => {
            if (response.status === 200) {
                alert("Uspesan update!");
            } else {
                alert("Neuspesan update!");

            }
            window.location.reload();
        });
    }

    return (
        <>
            <Header />
            <Container className='mt-5 pt-5'>

                {
                    (popularProducts) &&
                    <>
                        <p className="mt-5 mb-3 h5">Najprodavaniji proizvodi</p>
                        <Row className='bg-masa text-center text-white'>
                            <Col md='1' className='pt-3 p-0 col-2'>
                                <p>{`ID`}</p>
                            </Col>
                            <Col md='2' className='pt-3 p-0 col-2'>
                                <p>{`NAZIV`}</p>
                            </Col>
                            <Col md='3' className='pt-3 p-0 col-3'>
                                <p>{`BREND`}</p>
                            </Col>
                            <Col md='3' className='pt-3 p-0 col-3'>
                                <p>{`KATEGORIJA`}</p>
                            </Col>
                            <Col md='3' className='pt-3 p-0 col-3'>
                                <p>{`AKCIJE`}</p>
                            </Col>
                        </Row>
                        <form onSubmit={() => handleUpdate(1)}>
                            <Row className='text-center border-bottom-black'>
                                {
                                    p1 ?
                                        <>
                                            <Col md='1' className='pt-3 p-0 col-2'>
                                                <p>{p1.id}</p>
                                            </Col>
                                            <Col md='2' className='pt-3 p-0 col-2'>
                                                <p>{p1.naziv}</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{p1.brend}</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{p1.kategorija}</p>
                                            </Col>
                                            <Col md='3' className='p-0 pt-3'>
                                                <input type='text' style={{ width: '80px', marginRight: '10px'}} placeholder='Unesi id' onChange={(e) => setId(e.target.value)} />
                                                <LurreButton type='submit' style={{ height: '30px', minHeight: '0px', padding: '0px'}} text='Update' />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col md='9' className='py-2 p-0'>Proizvod ne postoji!</Col>
                                            <Col md='3' className='p-0 pt-1'>
                                                <input type='text' style={{ width: '80px', marginRight: '10px'}} placeholder='Unesi id' onChange={(e) => setId(e.target.value)} />
                                                <LurreButton type='submit' style={{ height: '30px', minHeight: '0px', padding: '0px'}} text='Update' />
                                            </Col>
                                        </>

                                }
                            </Row>
                        </form>
                        <form onSubmit={() => handleUpdate(2)}>
                        <Row className='text-center border-bottom-black'>
                                {
                                    p2 ?
                                        <>
                                            <Col md='1' className='pt-3 p-0 col-2'>
                                                <p>{p2.id}</p>
                                            </Col>
                                            <Col md='2' className='pt-3 p-0 col-2'>
                                                <p>{p2.naziv}</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{p2.brend}</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{p2.kategorija}</p>
                                            </Col>
                                            <Col md='3' className='p-0 pt-3'>
                                                <input type='text' style={{ width: '80px', marginRight: '10px'}} placeholder='Unesi id' onChange={(e) => setId(e.target.value)} />
                                                <LurreButton type='submit' style={{ height: '30px', minHeight: '0px', padding: '0px'}} text='Update' />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col md='9' className='py-2 p-0'>Proizvod ne postoji!</Col>
                                            <Col md='3' className='p-0 pt-1'>
                                                <input type='text' style={{ width: '80px', marginRight: '10px'}} placeholder='Unesi id' onChange={(e) => setId(e.target.value)} />
                                                <LurreButton type='submit' style={{ height: '30px', minHeight: '0px', padding: '0px'}} text='Update' />
                                            </Col>
                                        </>

                                }
                            </Row>
                        </form>
                        <form onSubmit={() => handleUpdate(3)}>
                        <Row className='text-center border-bottom-black'>
                                {
                                    p3 ?
                                        <>
                                            <Col md='1' className='pt-3 p-0 col-2'>
                                                <p>{p3.id}</p>
                                            </Col>
                                            <Col md='2' className='pt-3 p-0 col-2'>
                                                <p>{p3.naziv}</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{p3.brend}</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{p3.kategorija}</p>
                                            </Col>
                                            <Col md='3' className='p-0 pt-3'>
                                                <input type='text' style={{ width: '80px', marginRight: '10px'}} placeholder='Unesi id' onChange={(e) => setId(e.target.value)} />
                                                <LurreButton type='submit' style={{ height: '30px', minHeight: '0px', padding: '0px'}} text='Update' />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col md='9' className='py-2 p-0'>Proizvod ne postoji!</Col>
                                            <Col md='3' className='p-0 pt-1'>
                                                <input type='text' style={{ width: '80px', marginRight: '10px'}} placeholder='Unesi id' onChange={(e) => setId(e.target.value)} />
                                                <LurreButton type='submit' style={{ height: '30px', minHeight: '0px', padding: '0px'}} text='Update' />
                                            </Col>
                                        </>

                                }
                            </Row>
                        </form>
                        <form onSubmit={() => handleUpdate(4)}>
                        <Row className='text-center border-bottom-black'>
                                {
                                    p4 ?
                                        <>
                                            <Col md='1' className='pt-3 p-0 col-2'>
                                                <p>{p4.id}</p>
                                            </Col>
                                            <Col md='2' className='pt-3 p-0 col-2'>
                                                <p>{p4.naziv}</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{p4.brend}</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{p4.kategorija}</p>
                                            </Col>
                                            <Col md='3' className='p-0 pt-3'>
                                                <input type='text' style={{ width: '80px', marginRight: '10px'}} placeholder='Unesi id' onChange={(e) => setId(e.target.value)} />
                                                <LurreButton type='submit' style={{ height: '30px', minHeight: '0px', padding: '0px'}} text='Update' />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col md='9' className='py-2 p-0'>Proizvod ne postoji!</Col>
                                            <Col md='3' className='p-0 pt-1'>
                                                <input type='text' style={{ width: '80px', marginRight: '10px'}} placeholder='Unesi id' onChange={(e) => setId(e.target.value)} />
                                                <LurreButton type='submit' style={{ height: '30px', minHeight: '0px', padding: '0px'}} text='Update' />
                                            </Col>
                                        </>

                                }
                            </Row>
                        </form>
                        <form onSubmit={() => handleUpdate(5)}>
                        <Row className='text-center border-bottom-black'>
                                {
                                    p5 ?
                                        <>
                                            <Col md='1' className='pt-3 p-0 col-2'>
                                                <p>{p5.id}</p>
                                            </Col>
                                            <Col md='2' className='pt-3 p-0 col-2'>
                                                <p>{p5.naziv}</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{p5.brend}</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{p5.kategorija}</p>
                                            </Col>
                                            <Col md='3' className='p-0 pt-3'>
                                                <input type='text' style={{ width: '80px', marginRight: '10px'}} placeholder='Unesi id' onChange={(e) => setId(e.target.value)} />
                                                <LurreButton type='submit' style={{ height: '30px', minHeight: '0px', padding: '0px'}} text='Update' />
                                            </Col>
                                        </>
                                        :
                                        <>
                                            <Col md='9' className='py-2 p-0'>Proizvod ne postoji!</Col>
                                            <Col md='3' className='p-0 pt-1'>
                                                <input type='text' style={{ width: '80px', marginRight: '10px'}} placeholder='Unesi id' onChange={(e) => setId(e.target.value)} />
                                                <LurreButton type='submit' style={{ height: '30px', minHeight: '0px', padding: '0px'}} text='Update' />
                                            </Col>
                                        </>

                                }
                            </Row>
                        </form>
                    </>
                }
            </Container>
        </>
    );
}