import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductCard from "../pages/product-info";
import "../../assets/css/styles.css";
import axios from "axios";
import Config from "../../config/config";
import { firstLetter, removeUnderline, scrollToTop } from "../../utilities/util";
import history from "../../utilities/history";
import { Carousel } from '@trendyol-js/react-carousel';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

export default class CenterMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            popularProducts: null,
            p1: null,
            p2: null,
            p3: null,
            p4: null,
            p5: null,
        }
    }

    componentDidMount() {
        axios.get(`${Config.api.baseUrl}v1/popular-products`)
            .then(res => {
                this.setState({ popularProducts: res.data.data });
            })
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.popularProducts !== prevState.popularProducts) {
            await axios.get(`${Config.api.baseUrl}v1/proizvod/${this.state.popularProducts.p1}`)
                .then(res => {
                    this.setState({ p1: res.data.data });
                })
            await axios.get(`${Config.api.baseUrl}v1/proizvod/${this.state.popularProducts.p2}`)
                .then(res => {
                    this.setState({ p2: res.data.data });
                })
            await axios.get(`${Config.api.baseUrl}v1/proizvod/${this.state.popularProducts.p3}`)
                .then(res => {
                    this.setState({ p3: res.data.data });
                })
            await axios.get(`${Config.api.baseUrl}v1/proizvod/${this.state.popularProducts.p4}`)
                .then(res => {
                    this.setState({ p4: res.data.data });
                })
            await axios.get(`${Config.api.baseUrl}v1/proizvod/${this.state.popularProducts.p5}`)
                .then(res => {
                    this.setState({ p5: res.data.data });
                })

        }
    }

    render() {
        // const settings = {
        //     className: "center item-carousel",
        //     centerMode: true,
        //     infinite: true,
        //     centerPadding: "60px",
        //     slidesToShow: 4,
        //     speed: 500,
        // };
        return (
            <div className="my-5">
                {
                    this.state.p1 && this.state.p2 && this.state.p3 && this.state.p4 && this.state.p5 &&
                    <Carousel show={2.5} slide={2} swiping={true} infinite={true} leftArrow={<IoIosArrowBack style={{ height: '400px', width: '50px', color: 'white' }} />} rightArrow={<IoIosArrowForward style={{ height: '400px', width: '50px', color: 'white' }} />}>
                        {
                            this.state.p1 &&
                            <div>
                                <img className={"carousel-img"} src={process.env.PUBLIC_URL + `/Imgs/${removeUnderline(this.state.p1.defaultSlika)}`}
                                    alt={''}
                                    onClick={() => history.push(`/product/${this.state.p1.id}`)}
                                />
                            </div>
                        }
                        {
                            this.state.p2 &&
                            <div>
                                <img className={"carousel-img"} src={process.env.PUBLIC_URL + `/Imgs/${removeUnderline(this.state.p2?.defaultSlika)}`}
                                    alt={''}
                                    onClick={() => history.push(`/product/${this.state.p2?.id}`)}
                                />
                            </div>
                        }
                        {
                            this.state.p3 &&
                            <div>
                                <img className={"carousel-img"} src={process.env.PUBLIC_URL + `/Imgs/${removeUnderline(this.state.p3?.defaultSlika)}`}
                                    alt={''}
                                    onClick={() => history.push(`/product/${this.state.p3?.id}`)}
                                />
                            </div>
                        }
                        {
                            this.state.p4 &&
                            <div>
                                <img className={"carousel-img"} src={process.env.PUBLIC_URL + `/Imgs/${removeUnderline(this.state.p4?.defaultSlika)}`}
                                    alt={''}
                                    onClick={() => history.push(`/product/${this.state.p4?.id}`)}
                                />
                            </div>
                        }
                        {
                            this.state.p5 &&
                            <div>
                                <img className={"carousel-img"} src={process.env.PUBLIC_URL + `/Imgs/${removeUnderline(this.state.p5?.defaultSlika)}`}
                                    alt={''}
                                    onClick={() => history.push(`/product/${this.state.p5?.id}`)}
                                />
                            </div>
                        }
                    </Carousel>

                }
            </div>
        );
    }
}