import React, { useEffect, useState } from "react";
import '../../../assets/css/styles.css';
import './css/index.css';
import history from "../../../utilities/history";
import { currencyFormat, firstLetter, removeUnderline, scrollToTop } from "../../../utilities/util";
import Boje from "../../components/returnBoje";
import Velicine from "../../components/returnVelicine";
import { useMediaQuery } from 'react-responsive';

function ProductCard(props) {
    const [showSizes, setShowSizes] = useState(false);
    const noImage = process.env.PUBLIC_URL + `/Imgs/no-image.jpg`;
    const [src, setSrc] = useState(noImage);
    const product = props.product;
    const moda = product.moda ? 'Moda' : 'Klasika';
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        setSrc(process.env.PUBLIC_URL + `/Imgs/${removeUnderline(product.defaultSlika)}`);
    }, [product.defaultSlika])

    function calculateDiscount() {
        let discount = 0;
        if (product.discounts?.length > 0 || product.discountOne?.length > 0) {
            product.discounts?.forEach(item => {
                discount += Number(item.procenat);
            })
            product.discountOne?.forEach(item => {
                discount += Number(item.procenat);
            })
            return Math.round(discount * 100);
        }
        return null;
    }

    function calculatePrice(proizvod) {
        let price = proizvod.cena;
        if (proizvod.discounts?.length > 0 || proizvod.discountOne?.length > 0) {
            proizvod.discounts?.forEach(discount => {
                price -= proizvod.cena * discount.procenat;
            })
            proizvod.discountOne?.forEach(discount => {
                price -= proizvod.cena * discount.procenat;
            })
            return Math.round(price);
        }
    }

    return (
        <>
            <div className={`${props.shadow && 'shadow'} product-info-container ${props.carousel && 'carousel-size'}`} style={{ position: 'relative' }}
                onClick={() => history.push(`/product/${product.id}`)} onMouseOver={() => setShowSizes(true)}
                onMouseLeave={() => setShowSizes(false)}>
                <div className="product">
                    {
                        calculateDiscount() &&
                        <div className=''>
                            <p className='position-absolute discount-p'>{calculateDiscount()}%</p>
                            <img className='discount-photo' src={process.env.PUBLIC_URL + `/Imgs/discount.png`} />
                        </div>
                    }
                    <img
                        className={props.carousel ? "carousel-img" : "product-img"}
                        src={src}
                        alt={product.defaultSlika}
                        onError={
                            () => setSrc(noImage)
                        }
                    />
                </div>
                {
                    showSizes && !isMobile &&
                    <div className={`velicine text-center color-first ${props.carousel && 'w-250'}`}>
                        <p className='ff-arimo mb-0'>Veličine:</p>
                        <Velicine nameOfClass='size-item-product' product={product} floatEnd={false} />
                    </div>
                }
                <div className="product-details pt-1 ps-2 d-flex flex-column">
                    <div>
                        <div className="d-flex info-text">
                            <p className="mb-0" style={{ overflow: 'none' }}>{(product.brend).toUpperCase() + ' | ' + moda}  </p>
                        </div>
                        <p className="ff-arimo color-first mb-0">{firstLetter(product.naziv)}</p>
                    </div>
                    <div className="price" >
                        <div className="float-end pe-xl-2">
                            {calculatePrice(product) ?
                                <>
                                    <del>{currencyFormat(product.cena)}</del>&nbsp;
                                    {currencyFormat(calculatePrice(product))} RSD
                                </> :
                                <>{currencyFormat(product.cena)} RSD</>}
                        </div>
                    </div>
                    {/* <Boje nameOfClass='color-item-product' product={product} imgClass='rounded-1' showName={false}
                          floatEnd={false}/> */}
                </div>
            </div>
        </>
    );
}

export default ProductCard;
