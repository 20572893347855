import React, { useEffect } from "react";
import '../../../assets/css/styles.css';
import labels from '../../../language/srb';
import Container from 'react-bootstrap/Container';
import Header from "../../components/header";
import Footer from "../../components/footer";
import { scrollToTop } from "../../../utilities/util";

function AboutUsPage() {

    useEffect(() => {
        scrollToTop();
    }, [])

    return (
        <>
            <Header />
            <Container fluid className="pt-5 d-flex justify-content-center" style={{ backgroundColor: "#F4F4F4", height: "100%" }}>
                <div className='o-nama-container p-5' style={{ marginTop: '80px', maxWidth: "1250px", marginBottom: "80px" }}>
                    <p className="h4 ff-arimo mt-2 mb-5 text-center">Dobrodošli u Lurré</p>
                    <p className="h5 ff-arimo mb-5">
                        Tvoj kutak za savršeni modni doživljaj! U srcu Lurré-a krije se strast prema modi, udobnosti i stilu. Posvećeni smo pružanju vrhunskog iskustva kupovine modnog donjeg veša, čarapa i kupaćih kostima.
                    </p>
                    <p className="h5 mt-5 mb-5">Lormar, Sielei, Leilieve, Navigare, Selene, Jadea, Philippe Matignon i monte druge marke, deo su našeg asortimana. Znate šta još? Lurré misli da svaka dama zaslužuje bar neki od ovih komada, jer svaki ženski ormar voli Lurré!</p>
                    <p className="mt-5" style={{ float: 'right' }}>Vаš Lurré</p>
                </div>
            </Container>
            <Footer />
        </>
    );
}

export default AboutUsPage;
