import React, { useEffect, useRef, useState } from "react";
import '../../assets/css/styles.css';
import labels from '../../language/srb';
import SubHeader from './subHeader';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import history from '../../utilities/history';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import Filter from "../product-list/filter";
import axios from "axios";
import Config from "../../config/config";
import ReactDOM from "react-dom";
import { FaSearch, FaUser } from 'react-icons/fa';
import { BsInfoLg } from 'react-icons/bs';
import { IoAddCircle } from 'react-icons/io5';
import { GrMenu } from 'react-icons/gr';
import { FaShoppingCart } from 'react-icons/fa';
import store from "../../app/store";
import { clearSearchParams, setPolSearchParams, setSearchSearchParams } from "../../app/store/searchParams/actions";


function Header(props) {
    const [showSubMenu, setShowSubMenu] = useState(false);
    const [showInfoMenu, setShowInfoMenu] = useState(false);
    const [categoriesWoman, setCategoriesWoman] = useState(null);
    const [categoriesMan, setCategoriesMan] = useState(null);
    const [searchActive, setSearchActive] = useState(false);
    const [gender, setGender] = useState(true);
    const [showAdmin, setShowAdmin] = useState(false);
    const searchRef = useRef();

    function changeStyle(string) {
        let element = document.getElementById(string)
        ReactDOM.findDOMNode(element).style.display = 'none'
    }
    const { showMenu = true } = props;

    useEffect(() => {
        axios.get(`${Config.api.baseUrl}v1/kategorijatip?pol=zenski`)
            .then(res => {
                setCategoriesWoman(res.data.data);
            })
        axios.get(`${Config.api.baseUrl}v1/kategorijatip?pol=muski`)
            .then(res => {
                setCategoriesMan(res.data.data);
            })
        if (localStorage.getItem('BearerToken')) {
            axios.get(`${Config.api.baseUrl}v1/auth/login`, {
                headers: { "Authorization": localStorage.getItem("BearerToken") }
            }).then(res => {
                setShowAdmin(res.status === 200 ? true : false);
            })
        }
    }, []);

    return (
        <>
            <div className='header-desktop'>
                <Container fluid className="header-container">
                    <div className="container">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column" style={{ height: '112px', width: '328px', backgroundColor: 'black', cursor: "pointer", zIndex: '1' }}>
                                <img className="mx-auto" src={process.env.PUBLIC_URL + `/Imgs/new-logo-lurre.png`} style={{ padding: '10px', height: '112px', width: '272.16px' }} onClick={() => history.push('/home')} />
                                {
                                    showMenu &&
                                    <div style={{ backgroundColor: 'white', height: "60px", width: '60px', padding: '8px', cursor: 'pointer' }} onClick={() => setShowSubMenu(true)}>
                                        <GrMenu style={{ color: 'black', height: '45px', width: '45px' }} />
                                    </div>
                                }
                            </div>
                            {
                                showSubMenu &&
                                <SubHeader data={{ woman: categoriesWoman, man: categoriesMan }} onAbort={() => setShowSubMenu(false)} />
                            }
                            <ul className="header-items-right">
                                {/* <li className="header-item ">
                                    <p
                                        className='mb-0'
                                        onClick={() => {
                                            store.dispatch(clearSearchParams());
                                            setSearchActive(false);
                                            store.dispatch(setPolSearchParams('zenski'));
                                            history.push('/product-list');
                                        }}>{labels.woman}</p>
                                    <div id='subwoman' onClick={() => changeStyle('subwoman')} className='woman'>
                                        <SubHeader gender='zenski' data={categoriesWoman}/>
                                    </div>
                                </li>
                                <li
                                    className="header-item "

                                ><p
                                    className='mb-0'
                                    onClick={() => {
                                        store.dispatch(clearSearchParams());
                                        setSearchActive(false);
                                        store.dispatch(setPolSearchParams('muski'));
                                        history.push('/product-list');
                                    }}>{labels.man}</p>
                                    <div id='subman' onClick={() => changeStyle('subman')} className='man'>
                                        <SubHeader gender='muski' data={categoriesMan}/>
                                    </div>
                                </li>
                                <li className="header-item "
                                    onClick={() => history.push('/')}>Početna
                                </li> */}
                                <li className="header-item"
                                    onClick={() => history.push('/about-us')} style={{ borderRight: '1px solid black', paddingRight: '20px' }}>{labels.aboutUs}</li>
                                <li className="header-item"
                                    onClick={() => history.push('/contact')} style={{ paddingLeft: '20px' }}>{labels.contact}</li>
                                {
                                    !searchActive && props.isProductList &&
                                    <>
                                        <FaSearch className='search-img user-icon me-4 cursor-pointer' style={{ color: 'black' }} onClick={() => {
                                            setSearchActive(!searchActive)
                                        }} />
                                    </>
                                }
                                {
                                    searchActive &&
                                    <div className='search-bar mt-3 float-end'>
                                        <Row>
                                            <Col md='3 p-0'>
                                                <AiOutlineClose
                                                    className='float-end mt-2 w-20 h-20 cursor-pointer'
                                                    style={{ color: 'black' }}
                                                    onClick={() => {
                                                        setShowInfoMenu(false);
                                                        store.dispatch(setSearchSearchParams(''));
                                                        setSearchActive(false);
                                                    }} />
                                            </Col>
                                            <Col md='6'>
                                                <input id='search-input' className='search-input w-100' type='text' />
                                            </Col>
                                            <Col md='3 p-0'>
                                                <FaSearch className='w-20 h-20 mt-2 me-2 cursor-pointer' style={{ color: 'black' }} onClick={() => {
                                                    const searchTerm = document.getElementById('search-input').value;
                                                    store.dispatch(setSearchSearchParams(searchTerm));
                                                }} />
                                            </Col>
                                        </Row>
                                    </div>
                                }
                                {
                                    showAdmin &&
                                    <FaUser className='user-icon cursor-pointer' style={{ color: 'black' }}
                                        onClick={() => history.push('/admin-page')} />
                                }
                                {
                                    <FaShoppingCart className='user-icon cursor-pointer' style={{ color: 'black', marginLeft: '15px' }}
                                        onClick={() => history.push('/cart-page')} />
                                }
                            </ul>

                        </div>


                    </div>
                </Container>

            </div>
            <div className='header-mobile'>
                <Row>
                    <Col xs='3'>
                        <AiOutlineMenu onClick={() => setShowSubMenu(!showSubMenu)} className='mt-3 ms-3 menu-icon' style={{ position: 'relative', color: 'white' }} />
                    </Col>
                    <Col>
                        <div className="d-flex justify-content-center">
                            <img src={process.env.PUBLIC_URL + `/Imgs/new-logo-lurre.png`} style={{ padding: '10px', height: '65px', width: '156px' }} onClick={() => history.push('/home')} />
                        </div>
                    </Col>
                    <Col xs='3'>
                        <BsInfoLg className='mt-3 me-4 menu-icon float-end' style={{ color: 'white' }}
                            onClick={() => setShowInfoMenu(!showInfoMenu)} />
                    </Col>
                </Row>
                <div className={showSubMenu ? 'mobile-sub-menu show' : 'mobile-sub-menu'}>
                    <Row>
                        <Col />
                        <Col>
                            <ul className="header-items-middle">
                                <li className="logo-item" onClick={() => history.push('/home')}>Lurré</li>
                            </ul>
                        </Col>
                        <Col>
                            <AiOutlineClose onClick={() => setShowSubMenu(false)}
                                className='float-end mt-4 me-4 menu-icon' />
                        </Col>
                    </Row>
                    <Row className='my-2'>
                        <Col xs='6' className={gender ? 'button-active' : ''}>
                            <p className='text-center pt-3 fs-6 ff-syncopate-bold' onClick={() => {
                                store.dispatch(setPolSearchParams('zenski'));
                                history.push('/product-list');
                                setGender(true);
                            }}>ZA NJU</p>
                        </Col>
                        <Col xs='6' className={gender ? '' : 'button-active'}>
                            <p className='text-center pt-3 fs-6 ff-syncopate-bold' onClick={() => {
                                store.dispatch(setPolSearchParams('muski'));
                                history.push('/product-list');
                                setGender(false);
                            }}>ZA NJEGA</p>
                        </Col>
                    </Row>
                    <div className="filter-section pt-4 ps-4 pe-4">
                        {
                            gender ?
                                categoriesWoman?.map((catData) => {
                                    return (
                                        <>
                                            <div className='border-top pt-2' />
                                            <Filter filter={catData} mobile={true} gender='zenski' setShowSubMenu={setShowSubMenu} />
                                        </>
                                    );
                                }) :
                                categoriesMan?.map((catData) => {
                                    return (
                                        <>
                                            <div className='border-top pt-2' />
                                            <Filter filter={catData} mobile={true} gender='muski' />
                                        </>
                                    );
                                })

                        }
                    </div>
                </div>
                <div className={showInfoMenu ? 'mobile-sub-menu show' : 'mobile-sub-menu'}>
                    <Row>
                        <Col />
                        <Col>
                            <ul className="header-items-middle">
                                <li className="logo-item" onClick={() => history.push('/home')}>Lurré</li>
                            </ul>
                        </Col>
                        <Col>
                            <AiOutlineClose onClick={() => setShowInfoMenu(false)}
                                className='float-end mt-4 me-4 menu-icon' />
                        </Col>
                    </Row>
                    <div className="filter-section pt-4 ps-4 pe-4">
                        <p className="ff-arimo fs-3 py-3 border-bottom border-top"
                            onClick={() => history.push('/about-us')}>{labels.aboutUs}</p>
                        <p className="ff-arimo fs-3 pb-3 border-bottom"
                            onClick={() => history.push('/contact')}>{labels.contact}</p>
                        {
                            showAdmin &&
                            <p className="ff-arimo fs-3 pb-3 border-bottom"
                                onClick={() => history.push('/admin-page')} >Admin panel</p>
                        }
                    </div>
                </div>
            </div>


        </>
    );
}

export default Header;
