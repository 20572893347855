import React, {useEffect} from "react";
import '../../../assets/css/styles.css';
import './css/styles.css';
import labels from '../../../language/srb';
import Container from 'react-bootstrap/Container';
import Header from "../../components/header";
import Footer from "../../components/footer";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {scrollToTop} from "../../../utilities/util";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useMediaQuery } from 'react-responsive';

function ContactPage() {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        scrollToTop();
    }, [])

    return (
        <>
            <Header />
            {/* <Container className="mt-5 mt-20 pt-xs-5">
                <p className="h3 ff-arimo  mb-3">{labels.contact}</p>
                <Row>
                    <Col>
                        <div className="contact-info text-center">
                            <p className="h5 ff-arial pb-3 border-bottom-black">Preduzeće za unutrašnju i spoljnu trgovinu</p>
                            <p className="h5 ff-arial pb-3 pt-2 border-bottom-black">Veleprodaja donjeg veša, čarapa i kupaćih kostima</p>
                            <p className="h5 ff-arial pb-3 pt-2 border-bottom-black">YBC ul. Bulevar Mihajla Pupina 10D / II Jug N.P. 21, Novi Beograd</p>
                            <p className="h5 ff-arial pb-3 pt-2 border-bottom-black">Tel / Fax : Čarape : 011 / 2130 535, 311 0254</p>
                            <p className="h5 ff-arial pb-3 pt-2 border-bottom-black">Veš : 011 / 314 97 35, 313 9732, 2145 675</p>
                            <p className="h5 ff-arial pb-3 pt-2 border-bottom-black">(Veleprodaja) Radno vreme : Ponedeljak – Subota : 09h – 17h</p>
                            <p className="h5 ff-arial pb-3 pt-2 border-bottom-black">(Maloprodaja) Radno vreme : Ponedeljak – Petak : 09h – 20h : Subota : 09h - 16h</p>
                            <p className="h5 ff-arial mt-2 ">Email : officiallurre@gmail.com</p>
                        </div>
                    </Col>
                    <Col>
                        <div className="text-center">
                            <iframe
                                className="map-size"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2829.942527271884!2d20.417937915751065!3d44.82273548403519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a656fbf1b5b45%3A0x6d686d786ea8b382!2sTamara%20d.o.o.%20-%20Veleprodaja%20italijanskog%20ve%C5%A1a%2C%20%C4%8Darapa%20i%20kupa%C4%87ih%20kostima!5e0!3m2!1ssr!2srs!4v1635188415419!5m2!1ssr!2srs"
                                allowfullscreen=""
                                loading="lazy" />
                        </div>
                    </Col>
                </Row>
            </Container> */}
            <Container fluid className={`pt-5 d-flex justify-content-center ${isMobile && 'flex-column'}`} style={{backgroundColor: "#F4F4F4", height:"100%"}}>
                <div className={`o-nama-container ${!isMobile ? 'p-5' : 'p-2'}`} style={{marginTop: '80px', maxWidth: "1250px", marginBottom:"80px"}}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <p style={{fontWeight: "600", fontSize: "19px"}} className='text-center mb-0'>BUL. M. PUPINA 10d, V.P. BR. 61</p>
                            <p style={{fontWeight: "600", fontSize: "19px"}} className='text-center'>YBC NOVI BEOGRAD</p>
                            <div className="d-flex">
                            <FaPhoneSquareAlt style={{ height: '28px', width: "28px"}}/><p className="ps-3 font-weight-bold">063 / 252 558</p>
                            </div>
                            
                            <div className="d-flex">
                                <AiOutlineClockCircle style={{ height: '28px', width: "28px"}}/><p className="ps-3 mb-0 ">ponedeljak - petak</p>
                            </div>
                            <p className="ps-5 mb-0 font-weight-bold">09:00 - 20:00</p>
                            <p className="ps-5 mb-0">subota</p>
                            <p className="ps-5 mb-0 font-weight-bold">09:00 - 16:00</p>
                        </div>
                        <div>
                            <p style={{fontWeight: "600", fontSize: "19px"}} className='text-center mb-0'>MAKSIMA GORKOG 29</p>
                            <p style={{fontWeight: "600", fontSize: "19px"}} className='text-center'>VRAČAR</p>
                            <div className="d-flex">
                            <FaPhoneSquareAlt style={{ height: '28px', width: "28px"}}/><p className="ps-3 font-weight-bold">066 / 232 371</p>
                            </div>
                            
                            <div className="d-flex">
                                <AiOutlineClockCircle style={{ height: '28px', width: "28px"}}/><p className="ps-3 mb-0">ponedeljak - petak</p>
                            </div>
                            <p className="ps-5 mb-0 font-weight-bold">09:00 - 20:00</p>
                            <p className="ps-5 mb-0">subota</p>
                            <p className="ps-5 mb-0 font-weight-bold">09:00 - 16:00</p>
                        </div>
                        {
                            !isMobile &&
                            <div className="text-center">
                                <p style={{fontWeight: "600", fontSize: "19px"}}>EMAIL</p>
                                <p>officiallurre@gmail.com</p>
                            </div>
                        }
                    </div>
                    <div className="">
                        {
                            isMobile &&
                            <div className="text-center pt-5">
                                <p className='mb-0' style={{fontWeight: "600", fontSize: "19px"}}>EMAIL</p>
                                <p>officiallurre@gmail.com</p>
                            </div>
                        }
                    </div>
                    <div className={`d-flex mt-5 ${isMobile && 'flex-column'}`}>
                    <div className="text-center">
                            <p style={{fontWeight: "700", height: '25px', fontSize: '19px'}}>LOKACIJE</p>
                           <iframe
                                className="map-size"
                                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d176.94710544751393!2d20.476922441800934!3d44.79805646273967!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDTCsDQ3JzUzLjEiTiAyMMKwMjgnMzcuMSJF!5e0!3m2!1sen!2srs!4v1699018537308!5m2!1sen!2srs"
                                // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2829.942527271884!2d20.417937915751065!3d44.82273548403519!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a656fbf1b5b45%3A0x6d686d786ea8b382!2sTamara%20d.o.o.%20-%20Veleprodaja%20italijanskog%20ve%C5%A1a%2C%20%C4%8Darapa%20i%20kupa%C4%87ih%20kostima!5e0!3m2!1ssr!2srs!4v1635188415419!5m2!1ssr!2srs"
                                allowfullscreen=""
                                loading="lazy" />
                        </div>
                        <div className="text-center pt-5 ps-3 pr-3">
                            <p style={{fontWeight: "700", height: '25px'}}>
                            POSTANITE DEO LURRÉ TIMA!
                            </p>
                            <p>
                            Ukoliko smatrate da je Lurré brend čije vam se poslovanje sviđa, i brend sa kojim biste sarađivali, pišite nam na mail!
                            </p>
                            <p>Za sve detaljnije informacije kontaktirajte nas na broj telefona: 011 / 213 05 35 ili slanjem upita na mail: officiallurre@gmail.com.</p>
                        </div>
                    </div>
                </div>
            </Container>
            <Footer />
        </>
    );
}

export default ContactPage;
