import React, { useEffect, useState } from 'react';
import Header from "../../components/header";
import Footer from "../../components/footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import history from "../../../utilities/history";
import axios from "axios";
import Config from "../../../config/config";
import { scrollToTop } from "../../../utilities/util";
import { FaTshirt } from "react-icons/fa";
import { IoShirtOutline } from "react-icons/io5";
import { FaRegFolder } from "react-icons/fa";
import { FiLayers } from "react-icons/fi";
import { TbBrandApple } from "react-icons/tb";
import { IoIosColorPalette } from "react-icons/io";
import { CiRuler } from "react-icons/ci";
import { IoMailOpenOutline } from "react-icons/io5";
import { MdOutlineDiscount } from "react-icons/md";
import { MdOutlineShoppingCart } from "react-icons/md";
import { FaRegStar } from "react-icons/fa";
import { useMediaQuery } from 'react-responsive';
import "../../../assets/css/styles.css";

export default function AdminPage() {
    const [renderPage, setRenderPage] = useState(false);

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    useEffect(() => {
        scrollToTop();
        axios.get(`${Config.api.baseUrl}v1/auth/login`, {
            headers: { "Authorization": localStorage.getItem("BearerToken") }
        }).then(res => {
            setRenderPage(res.status === 200 ? true : false);
        })
    }, [])

    return (
        <>
            <Header />
            {
                renderPage ?
                    <div className="mt-50 pt-5">
                        <div className='d-flex flex-row justify-content-evenly flex-wrap align-content-start'>
                            {
                                isMobile ?
                                    <>
                                        <div className='panel-item mb-3' onClick={() => history.push('/add-product')}>
                                            <IoShirtOutline className='panel-img' />
                                            <p className='text-center h5'>Proizvodi</p>
                                        </div>
                                        <div className='panel-item mb-3' onClick={() => history.push('/add-to-db', { state: 'kategorije' })}>
                                            <FaRegFolder className='panel-img' />
                                            <p className='text-center h5'>Kategorije</p>
                                        </div>
                                        <div className='panel-item mb-3' onClick={() => history.push('/add-to-db', { state: 'tip' })}>
                                            <FiLayers className='panel-img' />
                                            <p className='text-center h5'>Tipovi</p>
                                        </div>
                                        <div className='panel-item mb-3' onClick={() => history.push('/add-to-db', { state: 'brend' })}>
                                            <TbBrandApple className='panel-img' />
                                            <p className='text-center h5'>Brendovi</p>
                                        </div>
                                        <div className='panel-item mb-3' onClick={() => history.push('/add-to-db', { state: 'boje' })}>
                                            <IoIosColorPalette className='panel-img' />
                                            <p className='text-center h5'>Boje</p>
                                        </div>
                                        <div className='panel-item mb-3' onClick={() => history.push('/add-to-db', { state: 'velicine' })}>
                                            <CiRuler className='panel-img' />
                                            <p className='text-center h5'>Veličine</p>
                                        </div>
                                        <div className='panel-item mb-3' onClick={() => history.push('/email-list')}>
                                            <IoMailOpenOutline className='panel-img' />
                                            <p className='text-center h5'>Mailing lista</p>
                                        </div>
                                        <div className='panel-item mb-3' onClick={() => history.push('/orders')}>
                                            <MdOutlineShoppingCart className='panel-img' />
                                            <p className='text-center h5'>Porudžbine</p>
                                        </div>
                                        <div className='panel-item mb-3' onClick={() => history.push('/add-discount')}>
                                            <MdOutlineDiscount className='panel-img' />
                                            <p className='text-center h5'>Popusti</p>
                                        </div>
                                        <div className='panel-item mb-3' onClick={() => history.push('/najprodavaniji-proizvodi')}>
                                            <FaRegStar className='panel-img' />
                                            <p className='text-center h5'>Najprodavaniji proizvodi</p>
                                        </div>

                                    </> :
                                    <>
                                        <Row>
                                            <Col className='panel-item mb-3 mx-2' onClick={() => history.push('/add-product')}>
                                                <IoShirtOutline className='panel-img' />
                                                <p className='text-center h5'>Proizvodi</p>
                                            </Col>
                                            <Col className='panel-item mb-3 mx-2' onClick={() => history.push('/add-to-db', { state: 'kategorije' })}>
                                                <FaRegFolder className='panel-img' />
                                                <p className='text-center h5'>Kategorije</p>
                                            </Col>
                                            <Col className='panel-item mb-3 mx-2' onClick={() => history.push('/add-to-db', { state: 'tip' })}>
                                                <FiLayers className='panel-img' />
                                                <p className='text-center h5'>Tipovi</p>
                                            </Col>
                                            <Col className='panel-item mb-3 mx-2' onClick={() => history.push('/add-to-db', { state: 'brend' })}>
                                                <TbBrandApple className='panel-img' />
                                                <p className='text-center h5'>Brendovi</p>
                                            </Col>
                                            <Col className='panel-item mb-3 mx-2' onClick={() => history.push('/add-to-db', { state: 'boje' })}>
                                                <IoIosColorPalette className='panel-img' />
                                                <p className='text-center h5'>Boje</p>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col className='panel-item mb-3 mx-2' onClick={() => history.push('/add-to-db', { state: 'velicine' })}>
                                                <CiRuler className='panel-img' />
                                                <p className='text-center h5'>Veličine</p>
                                            </Col>
                                            <Col className='panel-item mb-3 mx-2' onClick={() => history.push('/email-list')}>
                                                <IoMailOpenOutline className='panel-img' />
                                                <p className='text-center h5'>Mailing lista</p>
                                            </Col>
                                            <Col className='panel-item mb-3 mx-2' onClick={() => history.push('/orders')}>
                                                <MdOutlineShoppingCart className='panel-img' />
                                                <p className='text-center h5'>Porudžbine</p>
                                            </Col>
                                            <Col className='panel-item mb-3 mx-2' onClick={() => history.push('/add-discount')}>
                                                <MdOutlineDiscount className='panel-img' />
                                                <p className='text-center h5'>Popusti</p>
                                            </Col>
                                            <Col className='panel-item mb-3 mx-2' onClick={() => history.push('/najprodavaniji-proizvodi')}>
                                                <FaRegStar className='panel-img' />
                                                <p className='text-center h5'>Najprodavaniji proizvodi</p>
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </div>

                    </div> :
                    <p className='text-center h4  mt-20'>Molimo ulogujte se <a href="" className="link" onClick={() => history.push('/admin')}>ovde</a></p>
            }

        </>
    );
}