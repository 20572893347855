import Header from "../../components/header";
import Select from "react-select";
import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import axios from "axios";
import Config from "../../../config/config";
import { getValueFromMultiSelect, prepareForSelect } from "../../../utilities/util";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaTrashAlt } from "react-icons/fa";
import LurreButton from "../../components/lurreButton";

export default function AddDiscountPage() {
    const [apiCategories, setApiCategories] = useState(null);
    const [apiBrands, setApiBrands] = useState(null);
    const [apiColors, setApiColors] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [popusti, setPopusti] = useState(null);
    const [activeTab, setActiveTab] = useState('add-discount');

    useEffect(() => {
        axios.get(`${Config.api.baseUrl}v1/kategorijatip?pol=zenski`)
            .then(res => {
                setApiCategories(prepareForSelect(res.data.data, true));
            })
        axios.get(`${Config.api.baseUrl}v1/brend`)
            .then(res => {
                setApiBrands(prepareForSelect(res.data.data));
            })
        // axios.get(`${Config.api.baseUrl}v1/boje`)
        //     .then(res => {
        //         setApiColors(prepareForSelect(res.data.data));
        //     })
        axios.get(`${Config.api.baseUrl}v1/add-discount`, {
            headers: { "Authorization": localStorage.getItem("BearerToken") }
        }).then(res => {
            setPopusti(res.data.data);
        })
    }, [])

    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        const kategorije = getValueFromMultiSelect(form.kategorije);
        const brendovi = getValueFromMultiSelect(form.brendovi);
        // const boje = getValueFromMultiSelect(form.boje);
        const procenat = (form.procenat.value / 100);
        const naziv = form.naziv.value;

        axios.post(`${Config.api.baseUrl}v1/add-discount`, {
            kategorije: kategorije,
            brendovi: brendovi,
            // boje: boje,
            datumOd: startDate,
            datumDo: endDate,
            procenat: procenat,
            naziv: naziv
        }, {
            headers: { 'Authorization': localStorage.getItem('BearerToken') }
        }
        ).then((response) => {
            if (response.status === 200);
            alert("Uspesno dodat popust!");
            window.location.reload();
        });
    }
    const options = { day: '2-digit', month: 'short', year: '2-digit' };

    const handleDeleteDiscount = (id) => {
        axios.put(`${Config.api.baseUrl}v1/add-discount/update/${id}`, {}, {
            headers: { "Authorization": localStorage.getItem("BearerToken") }
        })
            .then((response) => {
                if (response.status === 200) {
                    alert("Uspesno obrisan popust!");
                } else {
                    alert("Neuspesno obrisan popust!");

                }
                window.location.reload();
            });
    }
    return (
        <>
            <Header />
            <Container className="pt-2">
                <div className="d-flex flex-row mt-5 pt-5 border-bottom cursor-pointer">
                    <p className={`${activeTab === 'add-discount' && 'font-weight-bold bg-light'} p-3`} onClick={() => setActiveTab('add-discount')}>Dodaj popust</p>
                    <p className={`${activeTab === 'discount-list' && 'font-weight-bold bg-light'} p-3`} onClick={() => setActiveTab('discount-list')}>Lista popusta</p>
                </div>
                {
                    activeTab === 'add-discount' &&
                    <>
                        <p className="mt-3 ms-3 h4">Kreiraj novi popust</p>
                        <div className='mt-1 d-flex flex-column'>
                            <form onSubmit={handleSubmit} className="">
                                <div className='m-3'>
                                    <p>Naziv: </p>
                                    <input type='text' name='naziv' />
                                </div>
                                <div className='m-3'>
                                    <Form.Label>Kategorije:</Form.Label>
                                    <Select
                                        isMulti
                                        name="kategorije"
                                        options={apiCategories}
                                        className="basic-multi-select"
                                        placeholder="Izaberi.."
                                    />
                                </div>
                                <div className='m-3'>
                                    <Form.Label>Brendovi:</Form.Label>
                                    <Select
                                        isMulti
                                        name="brendovi"
                                        options={apiBrands}
                                        className="basic-multi-select"
                                        placeholder="Izaberi.."
                                    />
                                </div>
                                {/*<div className='m-3'>*/}
                                {/*    <Form.Label>Boje:</Form.Label>*/}
                                {/*    <Select*/}
                                {/*        isMulti*/}
                                {/*        name="boje"*/}
                                {/*        options={apiColors}*/}
                                {/*        className="basic-multi-select"*/}
                                {/*        placeholder="Izaberi.."*/}
                                {/*    />*/}
                                {/*</div>*/}
                                <div className='m-3'>
                                    <p>Procenat: </p>
                                    <input type='text' name='procenat' /> <span>%</span>
                                </div>
                                <div className='m-3'>
                                    <p>Datum od:</p>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date: Date) => setStartDate(date)}
                                        showTimeSelect
                                        dateFormat="Pp" />
                                </div>
                                <div className='m-3'>
                                    <p>Datum od:</p>
                                    <DatePicker
                                        selected={endDate}
                                        onChange={(date: Date) => setEndDate(date)}
                                        showTimeSelect
                                        dateFormat="Pp" />
                                </div>
                                <div className='text-center'>
                                    <LurreButton type="submit" text="Primeni popust" />
                                </div>
                            </form>
                        </div>
                    </>
                }
                {
                    activeTab === 'discount-list' &&
                    <>
                        <div className='mt-3'>
                            <Row className='bg-masa text-center text-white'>
                                <Col md='3' className='pt-3 p-0 col-2'>
                                    <p>{`NAZIV`}</p>
                                </Col>
                                <Col md='1' className='pt-3 p-0 col-2'>
                                    <p>{`PROCENAT`}</p>
                                </Col>
                                <Col md='3' className='pt-3 p-0 col-3'>
                                    <p>{`DATUM OD`}</p>
                                </Col>
                                <Col md='3' className='pt-3 p-0 col-3'>
                                    <p>{`DATUM DO`}</p>
                                </Col>
                                <Col md='2' className='pt-3 p-0 col-3'>
                                    <p>{`AKCIJE`}</p>
                                </Col>
                            </Row>
                            {
                                popusti?.length === 0 &&
                                <p className="text-center mt-5 h5">
                                    Trenutno nema aktivnih popusta!
                                </p>
                            }
                            {
                                popusti && popusti.map(item => {
                                    return (
                                        <Row className='text-center border-bottom-black'>
                                            <Col md='3' className='pt-3 p-0 col-2'>
                                                <p>{item.naziv}</p>
                                            </Col>
                                            <Col md='1' className='pt-3 p-0 col-2'>
                                                <p>{item.procenat * 100}%</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{new Date(item.datumOd).toLocaleDateString('en-GB')}</p>
                                            </Col>
                                            <Col md='3' className='pt-3 p-0 col-3'>
                                                <p>{new Date(item.datumDo).toLocaleDateString('en-GB')}</p>
                                            </Col>
                                            <Col md='2' className='pt-3 p-0 col-3'>
                                                <FaTrashAlt className="cursor-pointer" style={{ height: '25px', width: '25px' }}
                                                    onClick={() => {
                                                        handleDeleteDiscount(item.id);
                                                    }} />
                                            </Col>
                                        </Row>
                                    );
                                })
                            }
                        </div>
                    </>
                }
            </Container>


        </>
    );
}