import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import AppRouter from '../routes';
import { useMediaQuery } from 'react-responsive';
import { FaShoppingCart } from "react-icons/fa";
import history from '../utilities/history';

const App: React.FC = () => {
  return (
    <div>
    <AppRouter />
    <div className='shopping-cart-mobile d-flex justify-content-center align-items-center'>
      <FaShoppingCart style={{ width: '40px', height: '40px', color: 'white'}} onClick={() => history.push('/cart-page')} />
    </div>
    </div>
  );
};

export default App;
